<template>
  <div class="v-playlists-info">
    <div class="black-mask">
    <breadcrumb />

    <div class="v-playlists-info__content g-content">
      <div class="main">

        <div class="main__content">
          <div class="main__content__left">

            <div class="cover">
              <img class="cover__img" src="@/assets/album/2.jpeg" />
            </div>

            <div class="tablet-show">
              <relatived-albums type="Playlists" />
            </div>
          </div>
          <div class="main__content__right">
            <playlists-albums-info />

            <my-music-list
              :config="listConfig"
              :contentData="contentData"
              :padding="false"
              :max100="true"
              :showHeader="false"
            />

            <div class="mobile-show">
              <relatived-albums type="Playlists" />
            </div>
          </div>
        </div>
      </div>

      <div class="btn-wrap">
        <button class="btn" @click="toSearchPage">
          <span class="btn__text">Refine this playlists in Search</span>
        </button>
      </div>
    </div>
    <site-map />
    </div>
  </div>
</template>
<script>
import {
  defineComponent, computed, ref, provide,
} from 'vue';
// import PlaylistsAlbumsSidebar from '@/components/Explore/PlaylistsAlbumsSidebar.vue';
import { useRouter } from 'vue-router';
import PlaylistsAlbumsInfo from '@/components/Explore/PlaylistsAlbumsInfo.vue';
// import AlbumsRow from '@/components/Explore/AlbumsRow.vue';
import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';
import {
  contentData,
  functionConfig,
  mobileListConfig,
  pcListConfig,
  musicDetailColumns,
  musicDetailContentData,
  trackDivideColumns,
  trackDivideData,
  mobileListDetailColumns,
  pcListDetailColumns,
  listDetailContentData,
} from '@/config/playlistsInfoConfig';
import RelativedAlbums from '@/components/Explore/RelativedAlbums.vue';
import Breadcrumb from '@/views/Explore/Breadcrumb.vue';
import { useSiteStore } from '@/store/site';
import SiteMap from '@/views/Home/SiteMap.vue';

export default defineComponent({
  name: 'AlbumsInfo',
  components: {
    // PlaylistsAlbumsSidebar,
    PlaylistsAlbumsInfo,
    MyMusicList,
    RelativedAlbums,
    Breadcrumb,
    SiteMap,
  },
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    const listContentConfig = ref({
      notice: '',
      expandDetail: 'music-detail', // list-detail, music-detail, ''
      moreDesc: true,
    });

    const musicDetail = ref({
      versionAndEdit: {
        columns: musicDetailColumns,
        data: musicDetailContentData,
      },
      track: {
        columns: trackDivideColumns,
        data: trackDivideData,
      },
      // mobile 點擊 row 展開的 key
      mobileExpandKey: 'detail',
    });

    const listConfig = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return pcListConfig;
      }

      return mobileListConfig;
    });

    const listDetailColumns = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return pcListDetailColumns;
      }

      return mobileListDetailColumns;
    });

    provide('listContentConfig', listContentConfig);
    provide('musicDetail', musicDetail);
    provide('detailConfig', () => ({
      listDetailColumns: listDetailColumns.value,
      listDetailContentData,
    }));

    const mockList = [
      {
        id: 1,
        name: 'name1',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 2,
        name: 'name2',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 3,
        name: 'name3',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 4,
        name: 'name4',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 5,
        name: 'name5',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 6,
        name: 'name6',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 7,
        name: 'name7',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 8,
        name: 'name8',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 9,
        name: 'name9',
        time: '03:17',
        track: 'MAT749-1',
      },
      {
        id: 10,
        name: 'name10',
        time: '03:17',
        track: 'MAT749-1',
      },
    ];

    const toSearchPage = () => {
      router.push({
        name: 'search',
        query: {
          tab: 'playlists',
        },
      });
    };

    return {
      mockList,
      functionConfig,
      listConfig,
      contentData,
      toSearchPage,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/blackMask.scss';
.v-playlists-info {
  padding-top: 60px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('~@/assets/album/8.jpeg');
  width: 100%;
  @include h100();
  overflow-y: auto;

  &--bg {
    &--licensing {
      background-image: url('~@/assets/licensing/bg.png');
    }

    &--front-contact {
      background-image: url('~@/assets/contact/bg.png');
    }
  }

  &__content {
  }
}

.back-search-wrap {
  display: none;
}

.main {
  background-color: $c-white3;
  border-radius: 10px;
  position: relative;
  margin-top: 16px;

  &__content {
    @include padding(15px);

    &__left {
    }

    &__right {
    }
  }
}

.cover {
  text-align: center;
  &__img {
    width: 100%;
    height: 100%;
    max-width: 330px;
    max-height: 330px;
    border-radius: 5px;
  }
}

.popular-genres-box-list {
  margin-top: 8px;

  &__item {
    & + & {
      margin-top: 20px;
    }

    &__box {
    }
  }
}

.btn-wrap {
  @include flex(center);
  margin-top: 50px;
  padding-bottom: 100px;
  position: relative;
    z-index: 10;
}

.btn {
  border: 1px solid $c-white;
  border-radius: 20px;
  @include flex(center);
  height: 40px;
  background-color: transparent;
  @include padding(0 18px);
  cursor: pointer;

  &__text {
    @include font-style($c-white, 14, 700, 0.7px, 18px);
    position: relative;
    z-index: 10;
  }
}

.tablet-show {
  display: none;
}

@media screen and (min-width: $tablet) {
  .mobile-show {
    display: none;
  }

  .tablet-show {
    display: block;
  }
  .v-playlists-info {
    padding-top: 80px;
    // padding-bottom: 150px;
    padding-bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('~@/assets/album/8.jpeg');

    &--bg {
      &--licensing {
        background-image: url('~@/assets/licensing/bg.png');
      }

      &--front-contact {
        background-image: url('~@/assets/contact/bg.png');
      }
    }
  }

  .back-search-wrap {
    @include flex(space-between);
  }
  .back-search {
    margin-top: 80px;
    &__text {
      @include font-style($c-white, 16, 600, 0.8px, 18px);
    }

    &__img {
      display: inline-block;
      margin-left: 12px;

      &--left {
        margin-right: 12px;
        margin-left: 0px;
        transform: rotate(180deg);
      }
    }
  }

  .main {
    background-color: $c-white3;
    border-radius: 10px;
    @include padding(73px 0);
    position: relative;
    margin-top: 16px;

    &__content {
      @include max-width(1366);
      @include flex(flex-start, flex-start);

      &__left {
        flex: none;
        width: 330px;
      }

      &__right {
        flex: 1;
        margin-left: 64px;
      }
    }
  }

  .popular-genres-box-list {
    margin-top: 8px;

    &__item {
      & + & {
        margin-top: 20px;
      }

      &__box {
      }
    }
  }

  .btn-wrap {
    margin-top: 70px;
  }
}
</style>
